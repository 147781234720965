Covers
  @srs_15.3 @srs_15.4 @srs_15.6

Date filter for index. The idea is to bind two properties to this component
to represent the chosen start and end timestamp. Example:

    <date-filter label="Last Updated"
      v-model:atGteq="query.updated_at_gteq"
      v-model:atLteq="query.updated_at_lteq" />

The user is presented a list of predefined buckets (such as "Past 7 Days").
They also have the option of picking a specific day or date range. The user
only selects the date but the timestamps generated are for midnight for the
start of the range and right before midnight for the end of the range.

<template>
  <div class="row">
    <container :label="label">
      <select v-model="at">
        <option v-if="includeBlank" :value="allOptionValue()"></option>
        <option v-for="option in options" :value="option[1]">
          {{ option[0] }}
        </option>
      </select>
    </container>

    <collapse>
      <container label="Date" v-if="type === 'specific-date'">
        <date-input v-model="on" />
      </container>

      <container label="Start" v-if="type === 'date-range'">
        <date-input v-model="onGteq" />
      </container>
    </collapse>

    <collapse>
      <container label="End" v-if="type === 'date-range'">
        <date-input v-model="onLteq" />
      </container>
    </collapse>
  </div>
</template>

<script>
import Container from 'index/filter/container'
import DateInput from 'bootstrap/date_input'
import Collapse from 'bootstrap/collapse'
import moment from 'moment-timezone'

const isoDate = 'YYYY-MM-DD'

export default {
  props: {
    label: {},
    atGteq: {},
    atLteq: {},
    includeBlank: { default: false },
    quickDirection: { default: 'past' },
  },
  emits: ['update:atGteq', 'update:atLteq'],
  components: { Container, DateInput, Collapse },
  created() {
    // So each Vue render doesn't slightly update the timestamps on the select
    this.now = moment()

    this.options = this.baseOptions()

    switch( this.quickDirection ) {
      case 'past':
        this.options.unshift(...this.pastQuickOptions())
        break
      case 'future':
        this.options.unshift(...this.futureQuickOptions())
        break
    }

    if( !this.includeBlank ) {
      if( !this.atGteq ) this.$emit('update:atGteq', this.options[0][1].gteq)
      if( !this.atLteq ) this.$emit('update:atLteq', this.options[0][1].lteq)
    }

    if( this.atGteq && this.atLteq ) {
      let predefined = this.options.some(([_label, value]) => {
        return value.type == 'predefined' &&
          this.atGteq == value.gteq &&
          this.atLteq == value.lteq
      })

      if( !predefined )
        if( moment(this.atGteq).isSame(this.atLteq, 'day') )
          this.type = 'specific-date'
        else
          this.type = 'date-range'
    }
  },

  data() {
    return { type: 'predefined' }
  },
  methods: {
    daysAgo(number) { return moment(this.now).subtract(number, 'days').startOf('day').format() },
    daysFromNow(number) { return moment(this.now).add(number, 'days').endOf('day').format() },
    startOfDay() { return moment(this.now).startOf('day').format() },
    endOfDay() { return moment(this.now).endOf('day').format() },
    endOfYesterday() { return moment(this.now).subtract(1, 'days').endOf('day').format() },
    startOfTomorrow() { return moment(this.now).add(1, 'days').startOf('day').format() },

    allOptionValue() {
      return { type: 'predefined', gteq: null, lteq: null }
    },

    baseOptions() {
      return [
        ['Specific Date', { type: 'specific-date' }],
        ['Date Range',    { type: 'date-range'    }],
      ]
    },

    pastQuickOptions() {
      return [
        ['Past 7 Days',   { type: 'predefined', gteq: this.daysAgo(7),   lteq: this.endOfDay()       }],
        ['Past 30 Days',  { type: 'predefined', gteq: this.daysAgo(30),  lteq: this.endOfDay()       }],
        ['Past 90 Days',  { type: 'predefined', gteq: this.daysAgo(90),  lteq: this.endOfDay()       }],
        ['Yesterday',     { type: 'predefined', gteq: this.daysAgo(1),   lteq: this.endOfYesterday() }],
        ['Today',         { type: 'predefined', gteq: this.startOfDay(), lteq: this.endOfDay()       }],
        ['Next 7 Days',   { type: 'predefined', gteq: this.startOfDay(), lteq: this.daysFromNow(7)   }],
      ]
    },

    futureQuickOptions() {
      return [
        ['Next 7 Days',   { type: 'predefined', gteq: this.startOfDay(),      lteq: this.daysFromNow(7)  }],
        ['Next 30 Days',  { type: 'predefined', gteq: this.startOfDay(),      lteq: this.daysFromNow(30) }],
        ['Next 90 Days',  { type: 'predefined', gteq: this.startOfDay(),      lteq: this.daysFromNow(90) }],
        ['Tomorrow',      { type: 'predefined', gteq: this.startOfTomorrow(), lteq: this.daysFromNow(1)  }],
        ['Today',         { type: 'predefined', gteq: this.startOfDay(),      lteq: this.endOfDay()      }],
        ['Past 7 Days',   { type: 'predefined', gteq: this.daysAgo(7),        lteq: this.endOfDay()      }],
      ]
    },
  },
  computed: {
    at: {
      get() {
        if( this.type == 'predefined' )
          return {
            type: this.type,
            gteq: this.atGteq,
            lteq: this.atLteq,
          }
        else
          return { type: this.type }
      },

      set(val) {
        this.type = val.type
        if( this.type == 'predefined' ) {
          this.$emit('update:atGteq', val.gteq)
          this.$emit('update:atLteq', val.lteq)
        }
      },
    },

    on: {
      get() {
        this.$emit('update:atGteq', moment(this.atGteq).startOf('day').format())
        this.$emit('update:atLteq', moment(this.atGteq).endOf('day').format())

        return moment(this.atGteq).format(isoDate)
      },

      set(val) {
        this.$emit('update:atGteq', moment(val).format())
        this.$emit('update:atLteq', moment(val).endOf('day').format())
      },
    },

    onGteq: {
      get() {
        if( !this.atGteq ) return this.atGteq;
        return moment(this.atGteq).format(isoDate)
      },

      set(val) {
        this.$emit('update:atGteq', moment(val).format())
      },
    },

    onLteq: {
      get() {
        if( !this.atLteq ) return this.atLteq;
        return moment(this.atLteq).format(isoDate)
      },

      set(val) {
        this.$emit('update:atLteq', moment(val).endOf('day').format())
      },
    },
  }
}
</script>
